import { ActionType } from "./types";
import { AnyAction } from "redux";

const initialState = {
  currentUser: {},
  isLoggedIn: false,
  operater: "",
  isAuthenticated: false,
};

export const userReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        currentUser: action.payload,
        isAuthenticated: true,
        operater: action.payload.operater,
      };
    case ActionType.CHECK_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case ActionType.LOGOUT:
      return {
        currentUser: {},
        isLoggedIn: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
