import { ActionType } from "./types";
import { AnyAction } from "redux";

const initialState = {
  products: [],
  categories: [],
  category: {},
};

export const productReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case ActionType.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case ActionType.SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    default:
      return state;
  }
};
