import {
  Autocomplete,
  Box,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import { ReactNode } from "react";
import { Company } from "../../typescript/company";

const Loader = ({ label }: any) => (
  <Select value={"load"} fullWidth disabled>
    <MenuItem value={"load"}>
      <Skeleton variant="text" />
    </MenuItem>
  </Select>
);

const AutoComplete = ({
  options,
  formik,
  changeHandler,
  label,
  id,
  isLoaded,
}: any) => {
  return (
    <Box sx={{ mt: 3, mb: 2, boxShadow: 3 }}>
      {isLoaded ? (
        <Autocomplete
          disablePortal
          disableClearable
          id={id}
          options={options}
          value={formik.values[id]}
          onChange={changeHandler}
          getOptionLabel={(option: Company) =>
            option.label ? option.label : ""
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          includeInputInList
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={formik.touched[id] && Boolean(formik.errors[id])}
              helperText={
                (formik.touched[id] as ReactNode) &&
                (formik.errors[id] as ReactNode)
              }
              required
            />
          )}
          defaultValue={formik.initialValues[id]}
        />
      ) : (
        <Loader label={label} />
      )}
    </Box>
  );
};

export default AutoComplete;
