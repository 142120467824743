import { ActionType } from "./types";

export const setCompanies = (companies: any) => {
  return {
    type: ActionType.SET_COMPANIES,
    payload: companies,
  };
};

export const setCompany = (company: any) => {
  return {
    type: ActionType.SET_COMPANY,
    payload: company,
  };
};

export const setShops = (shops: any) => {
  return {
    type: ActionType.SET_SHOPS,
    payload: shops,
  };
};

export const setShop = (shop: any) => {
  return {
    type: ActionType.SET_SHOP,
    payload: shop,
  };
};
