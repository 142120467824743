import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Box } from "@mui/material";
import { useFormik } from "formik";
import { object } from "yup";
import { RootState } from "../redux/rootReducer";
import { fetchCompanies, getBalance } from "../endpoints/api";
import { setCompanies } from "../redux/company";
import AutoComplete from "../components/FormikInput/AutoComplete";
import FinanceData from "../components/FinanceData";
import FinanceDetails from "../components/FinanceDetails";
import { chooseCompany, chooseKonto } from "../utils/validators";
import { Finance as FinanceType } from "../typescript/finance";

const Finance = () => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const [data, setData] = useState({});
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [companyDetailsLoaded, setCompanyDetailsLoaded] = useState(false);

  const validationSchema = object({
    company: chooseCompany,
    konto: chooseKonto,
  });

  const { companies } = useSelector((state: RootState) => state.companyReducer);

  useEffect(() => {
    if (!companies.length) {
      fetchCompanies().then((data: any) => {
        dispatch(setCompanies(data?.firms[0]));
      });
    }
    setCompaniesLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const konto = [
    { id: "2020", label: "2020 - Kupci" },
    { id: "2021", label: "2021 - Kupci u maloprodaji" },
    { id: "4330", label: "4330 - Dobavljači" },
    { id: "4340", label: "4340 - Ino dobavljači" },
  ];

  const formik = useFormik({
    initialValues: {
      company: null,
      konto: null,
    },
    validationSchema,
    onSubmit: (values: any) => {
      setCompanyDetailsLoaded(false);
      getBalance({ konto: values.konto.id, supplier_id: values.company.id })
        .then((e: any) => {
          setData(e.finances[0][0]);
          setCompanyDetailsLoaded(true);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });
  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" ref={formRef} onSubmit={formik.handleSubmit}>
        <Box sx={{ mt: 3, mb: 2, boxShadow: 3 }}>
          <AutoComplete
            isLoaded={companiesLoaded}
            options={companies}
            formik={formik}
            changeHandler={(e: any, value: any) => {
              formik.setFieldValue("company", value).then(() =>
                formik.validateForm().then((e: any) => {
                  if (!Object.keys(e).length) {
                    formik.submitForm();
                  }
                })
              );
            }}
            label="Odaberite firmu"
            id="company"
          />
        </Box>
        <Box sx={{ mt: 3, mb: 2, boxShadow: 3 }}>
          <AutoComplete
            options={konto}
            formik={formik}
            changeHandler={(e: any, value: any) => {
              formik.setFieldValue("konto", value).then(() =>
                formik.validateForm().then((e: any) => {
                  if (!Object.keys(e).length) {
                    setCompanyDetailsLoaded(false);
                    formik.submitForm();
                  }
                })
              );
            }}
            label="Odaberite konto"
            id="konto"
            isLoaded={true}
          />
        </Box>

        {formik.values.company && formik.values.konto && (
          <>
            <FinanceData
              isLoaded={companyDetailsLoaded}
              {...(data as FinanceType)}
            />
            <FinanceDetails
              company={formik.values.company}
              konto={formik.values.konto}
            />
          </>
        )}
      </Box>
    </Container>
  );
};

export default Finance;
