import { Navigate, Route, Routes } from "react-router-dom";
import Authorized from "./auth/Authorized";
import Login from "./auth/Login";
import { ROUTES } from "./constants/routes";
// import AdminDailyReport from "./pages/AdminDailyReport";
// import ChoseShop from "./pages/ChoseShop";
// import DailyReport from "./pages/DailyReport";
import Finance from "./pages/Finance";
// import HomePage from "./pages/HomePage";
// import Order from "./pages/Order";
// import ShoppingCart from "./pages/ShoppingCart";

const Router = () => {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route element={<Authorized />}>
        {/* <Route path={ROUTES.HOME} element={<HomePage />} /> */}
        {/* <Route path={ROUTES.CHOSE_SHOP} element={<ChoseShop />} /> */}
        {/* <Route path={ROUTES.ORDER} element={<Order />} /> */}
        {/* <Route path={ROUTES.DAILY_REPORT} element={<DailyReport />} /> */}
        {/* <Route
          path={ROUTES.ADMIN_DAILY_REPORT}
          element={<AdminDailyReport />}
        /> */}
        {/* <Route path={ROUTES.SHOPPING_CART} element={<ShoppingCart />} /> */}
        <Route path={ROUTES.FINANCE} element={<Finance />} />
      </Route>
      <Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />
    </Routes>
  );
};

export default Router;
