import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { API_URL } from "../constants/api";

/**
 * Creates new instance of axios with baseUrl pre-set.
 * Uses interceptors to attach headers such as user token
 */
const instance: AxiosInstance = axios.create({ baseURL: API_URL });

/**
 * Create an interceptor for requests that attaches user
 * token in every request sent to the API.
 */
instance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (localStorage.getItem("token")) {
    config.headers = {
      "x-auth-token": localStorage.getItem("token") ?? "",
    };
  }
  return config;
});

/**
 * Response interceptor that handles returned token, Bad Request, Unauthorized and Internal Server Error
 * responses from the API.
 */
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (typeof response.data === "object" && "user" in response.data) {
      const { user } = response.data;
      localStorage.setItem("token", user.token);
    }
    return response.data;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.clear();
    }
    throw error.response?.data;
  }
);

interface AxiosClient {
  post: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => AxiosPromise<any>;
  get: ({
    url,
    params,
  }: {
    url: string;
    params?: Record<string, string>;
  }) => AxiosPromise<any>;
}

/**
 * Handler for HTTP calls sent to backend server
 *
 */
export const axiosClient: AxiosClient = {
  post: (
    url: string,
    xForm,
    options = { headers: { "Content-Type": "application/json" } }
  ) => instance.post(`${API_URL}${url}`, xForm, options),
  get: ({ url, params }) =>
    instance.get(`${API_URL}${url}`, {
      params,
    }),
};

export default instance;
