import { ActionType } from "./types";
import { AnyAction } from "redux";
import { CartItemT } from "../../typescript/item";

interface StateT {
  items: CartItemT[];
  itemCount: number;
  total: number;
}

const initialState: StateT = {
  items: [],
  itemCount: 0,
  total: 0,
};

export const cartReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    // Reducing item quantity by one
    case ActionType.DECREMENT_ITEM:
      const { id } = action.payload;
      let index = state.items.findIndex(({ item }) => item.id === id);
      console.log(id);
      let foundItem = state.items[index];
      let updatedItems;

      if (foundItem.quantity === 1) {
        updatedItems = state.items.filter(({ item }) => item.id !== id);
      } else {
        let updatedItem = {
          ...foundItem,
          quantity: foundItem.quantity - 1,
        };
        updatedItems = [...state.items];
        updatedItems[index] = updatedItem;
      }
      return {
        ...state,
        items: updatedItems,
        itemCount: state.itemCount - 1,
        total: state.total - foundItem.item.price,
      };

    // Adding item to cart
    case ActionType.SET_ITEM:
      const { item: newItem, quantity } = action.payload;
      let itemIndex = state.items.findIndex(
        (item) => item.item.id === newItem.id
      );
      let item = state.items[itemIndex];
      let items_;
      if (item) {
        let item_ = {
          ...item,
          quantity: item.quantity + action.payload.quantity,
        };
        items_ = [...state.items];
        items_[itemIndex] = item_;
      } else {
        items_ = state.items.concat(action.payload);
      }
      return {
        ...state,
        items: items_,
        total: state.total + quantity * newItem.price,
        itemCount: state.itemCount + quantity,
      };

    // Removing item from cart
    case ActionType.REMOVE:
      const deletingItem = state.items.find(
        ({ item }) => item.id === action.payload
      );
      const items = state.items.filter(
        ({ item }) => item.id !== action.payload
      );
      return {
        ...state,
        items: items,
        itemCount: state.itemCount - deletingItem!.quantity,
        total: state.total - deletingItem!.quantity * deletingItem!.item.price,
      };

    // Reseting cart to inital value
    case ActionType.RESET:
      return initialState;
    default:
      return state;
  }
};
