// import logOutAPI from "./pages/auth/api/logout";
import { tokenIsValid } from "../../endpoints/api";
import { ActionType } from "./types";

export const setUser = (user: any) => {
  return {
    type: ActionType.SET_USER,
    payload: user,
  };
};

export const checkAuth = () => {
  let isAuthenticated = false;
  if (localStorage.getItem("token"))
    tokenIsValid()
      .then(() => (isAuthenticated = true))
      .catch(() => {});
  return {
    type: ActionType.CHECK_AUTH,
    payload: isAuthenticated,
  };
};

// export const startLogOut = () => {
//   return async (dispatch) => {
//     logOutAPI()
//       .then(() => {
//         dispatch(logOut);
//       })
//       .catch((er) => console.log(er));
//   };
// };

export const logout = () => {
  return {
    type: ActionType.LOGOUT,
  };
};
