export const API_URL = "https://milovicapi.mils.me";
// export const API_URL = "http://api.agromont.mils.me";

// process.env.NODE_ENV === "production"
//     ? "https://agromont.com"
//     : "http://localhost:3030";

export enum API {
  CHECK_TOKEN = "/users/check-token",
  LOGIN = "/auth/login",
  LOGOUT = "/auth/logout",
  PRODUCTS = "/products",
  COMPANIES = "/products/firms",
  SHOPS = "/products/shops",
  CATEGORIES = "/products/categories",
  SUBMIT_ORDER = "/order",
  INVOICES = "/report/invoices",
  INVOICE_ITEMS = "/report/invoice/items",
  USERS = "/users",
  ANALYTIC = "/finance",
  BALANCE = "/finance/getBalance",
}
