import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setNotification } from "../../redux/notification";
import { RootState } from "../../redux/rootReducer";
import { MenuItem as Type } from "../../typescript/menu";

const MenuItem = ({ icon, label, route, onClick, selected = false }: Type) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { role } = useSelector(
    (state: RootState) => (state.userReducer as any).currentUser
  );

  return (
    <ListItem
      disablePadding
      sx={{ display: "block" }}
      // onClick={() => (route ? navigate(route) : onClick ? onClick() : null)}
      onClick={() => {
        if (role === "user") {
          if (route === "/finance" || route === "/adminDailyReport") {
            dispatch(
              setNotification({
                message: "Nemate pravo pristupa!",
                icon: "error",
              })
            );
          } else {
            route && navigate(route);
          }
        } else {
          console.log(route);
          if (route === "/dailyReport") {
            let newRoute = "/adminDailyReport";
            navigate(newRoute);
            return;
          }
          route && navigate(route);
        }
      }}
    >
      <ListItemButton selected={pathname === route || selected}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {label && <ListItemText primary={label} />}
      </ListItemButton>
    </ListItem>
  );
};

export default MenuItem;
