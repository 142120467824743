import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { object } from "yup";
import { usernameValidator, passwordValidator } from "../utils/validators";
import { CssBaseline, Box, styled } from "@mui/material";
import agromont from "../assets/img/agromont-removebg-preview.png";
import LoadingButton from "@mui/lab/LoadingButton";
import { login, tokenIsValid } from "../endpoints/api";
import { ROUTES } from "../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/user";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/rootReducer";
import { setNotification } from "../redux/notification";
import TextField from "../components/FormikInput/TextField";

const validationSchema = object({
  username: usernameValidator,
  password: passwordValidator,
});

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: RootState) => (state.userReducer as any).isAuthenticated
  );

  useEffect(() => {
    if (!localStorage.getItem("token")) return;
    tokenIsValid()
      .then((e) => {
        navigate(ROUTES.FINANCE);
      })
      .catch((e) =>
        dispatch(setNotification({ message: e.message, icon: "error" }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      login(values)
        .then((e: any) => {
          dispatch(setUser(e.user));
        })
        .catch((e: any) => {
          dispatch(setNotification({ message: e.message, icon: "error" }));
          setLoading(false);
        });
      formik.resetForm();
    },
  });

  return (
    <Box
      className="loginBck"
      component="main"
      maxWidth="xs"
      sx={{ padding: "10px" }}
    >
      <CssBaseline />
      <Container>
        <Box mt="20px" mb="25%">
          <img src={agromont} alt="logo" />
        </Box>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField formik={formik} name="username" />
          <TextField formik={formik} name="password" type="password" />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={loading}
            sx={{ mt: 3, mb: 2, height: "3rem", boxShadow: 3 }}
          >
            Log In
          </LoadingButton>
        </Box>
      </Container>
    </Box>
  );
};

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  marginInline: "auto",
});

export default Login;
