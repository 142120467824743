import { ActionType } from "./types";
import { AnyAction } from "redux";
import moment from "moment";

const initialState = {
  invoices: [],
  date: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
};

export const invoiceReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case ActionType.SET_DATE:
      return {
        ...state,
        date: action.payload,
        invoices: [],
      };
    default:
      return state;
  }
};
