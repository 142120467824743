import { ActionType } from "./types";
import { AnyAction } from "redux";

const initialState = {
  notification: null,
};

export const notificationReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case ActionType.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};
