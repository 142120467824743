import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import SideMenu from "./SideMenu";
import { ROUTES } from "../../constants/routes";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";

const TopBar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayCart, setDisplayCart] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // const itemCount = useSelector(
  //   (state: RootState) => (state.cartReducer as any).itemCount
  // );

  const items = useSelector(
    (state: RootState) => (state.cartReducer as any).items
  );

  useEffect(() => {
    setDisplayCart(location.pathname === "/order");
  }, [location.pathname]);

  return (
    <>
      <Container>
        <AppBar position="static">
          <Toolbar>
            <Container>
              <IconButton color="inherit" onClick={() => setIsOpen(true)}>
                <MenuIcon />
              </IconButton>
            </Container>

            {displayCart && (
              <IconButton
                color="inherit"
                aria-label="add to shopping cart"
                sx={{ position: "relative" }}
                onClick={() => {
                  navigate(ROUTES.SHOPPING_CART);
                }}
              >
                <ShoppingCartIcon />
                <ItemsNumber>{items.length}</ItemsNumber>
              </IconButton>
            )}
            <ThemeSwitch />
          </Toolbar>
        </AppBar>
      </Container>

      {/* SIDEMENU */}
      <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

const Container = styled(Box)({
  flexGrow: 1,
});

const ItemsNumber = styled("span")({
  fontSize: "0.8rem",
  padding: "0.15rem",
  position: "absolute",
  top: 0,
  right: 0,
  background: "rgba(244, 67, 54, 0.8)",
  borderRadius: "0.5rem",
});

export default TopBar;
