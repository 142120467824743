import { ActionType } from "./types";

export const setProducts = (products: any) => {
  return {
    type: ActionType.SET_PRODUCTS,
    payload: products,
  };
};

export const setCategories = (categories: any) => {
  return {
    type: ActionType.SET_CATEGORIES,
    payload: categories,
  };
};

export const setCategory = (category: any) => {
  return {
    type: ActionType.SET_CATEGORY,
    payload: category,
  };
};
