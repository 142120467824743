import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Routes from "./Router";
import { useToggleColorMode } from "./utils/useToggleColorMode";
import "./assets/styles/index.css";
import Notifications from "./components/Notifications";
import NoInternet from "./components/NoInternet";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

function App() {
  const { colorMode, theme } = useToggleColorMode();

  return (
    <NoInternet>
      <Notifications>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </Notifications>
    </NoInternet>
  );
}

export default App;
