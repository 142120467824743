import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  [x: string]: any;
}

export const TextGray = ({ children, ...props }: PropsWithChildren<Props>) => (
  <Typography sx={{ opacity: 0.7, fontWeight: "600" }} {...props}>
    {children}
  </Typography>
);

export const TextHeading = ({
  children,
  ...props
}: PropsWithChildren<Props>) => (
  <Typography variant="h6" fontWeight="700" {...props}>
    {children}
  </Typography>
);
